import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["frame", "scrollbar", "scrollbarHandle"];
  isDragging = false;
  lastPageX = 0;
  observer = null;
  visibleFramesCount = 0;

  connect() {
    this.setupIntersectionObserver();
    this.frameTargets.forEach(frame => {
      frame.addEventListener("scroll", this.handleScroll.bind(this));
    });
    const scrollbar = this.scrollbarTarget;
    scrollbar.addEventListener("mousedown", this.startDrag.bind(this));
    scrollbar.addEventListener("click", this.handleClick.bind(this));
    document.addEventListener("mousemove", this.onDrag.bind(this));
    document.addEventListener("mouseup", this.stopDrag.bind(this));
    this.updateScrollbar();
  }

  disconnect() {
    if (this.observer) {
      this.observer.disconnect();
    }
    this.frameTargets.forEach(frame => {
      frame.removeEventListener("scroll", this.handleScroll.bind(this));
    });
    const scrollbar = this.scrollbarTarget;
    scrollbar.removeEventListener("mousedown", this.startDrag.bind(this));
    scrollbar.removeEventListener("click", this.handleClick.bind(this));
    document.removeEventListener("mousemove", this.onDrag.bind(this));
    document.removeEventListener("mouseup", this.stopDrag.bind(this));
  }

  handleScroll(event) {
    const sourceElement = event.target;
    const otherFrames = this.frameTargets.filter(frame => frame !== sourceElement);
    otherFrames.forEach(frame => frame.scrollLeft = sourceElement.scrollLeft);
    this.updateScrollbar();
  }

  setupIntersectionObserver() {
    this.visibleFramesCount = this.frameTargets.length;
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        this.visibleFramesCount += entry.isIntersecting ? 1 : -1;
      });
      this.updateScrollbarVisibility();
    }, { root: null });

    this.frameTargets.forEach(frame => this.observer.observe(frame));
  }

  updateScrollbarVisibility() {
    this.scrollbarTarget.style.opacity = this.visibleFramesCount > 0 ? 1 : 0;
  }

  nearestFrame() {
    const scrollY = window.scrollY;
    let nearestFrame = this.frameTarget;
    let minDistance = Math.abs(nearestFrame.getBoundingClientRect().top - scrollY);

    this.frameTargets.forEach(frame => {
      const distance = Math.abs(frame.getBoundingClientRect().top - scrollY);
      if (distance < minDistance) {
        nearestFrame = frame;
        minDistance = distance;
      }
    });

    return nearestFrame;
  }

  updateScrollbar() {
    const frame = this.nearestFrame();
    const scrollbarHandle = this.scrollbarHandleTarget;
    const { scrollWidth, clientWidth, scrollLeft } = frame;
    const scrollbarWidth = this.scrollbarTarget.parentNode.offsetWidth;
    const scrollbarPosition = (scrollLeft / scrollWidth) * scrollbarWidth;
    const scrollbarHandleWidth = (clientWidth / scrollWidth) * scrollbarWidth;

    scrollbarHandle.style.width = `${scrollbarHandleWidth}px`;
    scrollbarHandle.style.transform = `translateX(${scrollbarPosition}px)`;
  }

  startDrag(event) {
    this.isDragging = true;
    this.lastPageX = event.pageX;
    event.preventDefault();
  }

  onDrag(event) {
    if (!this.isDragging) return;
    const deltaX = event.pageX - this.lastPageX;
    this.lastPageX = event.pageX;
    this.scrollBy(deltaX);
  }

  stopDrag() {
    this.isDragging = false;
  }

  handleClick(event) {
    const scrollbar = this.scrollbarTarget;
    if (event.target !== scrollbar) return;
    const clickPosition = event.pageX - scrollbar.getBoundingClientRect().left;
    const frame = this.nearestFrame();
    const newPosition = (clickPosition / scrollbar.parentNode.offsetWidth) * frame.scrollWidth;
    frame.scrollLeft = newPosition - frame.clientWidth / 2;
  }

  scrollBy(deltaX) {
    const frame = this.nearestFrame();
    const moveRatio = frame.scrollWidth / this.scrollbarTarget.parentNode.offsetWidth;
    frame.scrollLeft += deltaX * moveRatio;
  }
}